import Vue from 'vue';
import store from '../store'
import changePhone from './changePhone.vue'

let thechangePhone = function (content) {
  let Constructor = Vue.extend(changePhone);
  return new Promise((res, rej) => {
    let DOM = new Constructor({
      el: document.createElement('div'),
      store
    })
    document.body.appendChild(DOM.$el);

    DOM.ok = function (value) {
      res(value)
    }
    DOM.cancel = function (value) {
      document.body.removeChild(DOM.$el);
    }
  })
}
export default thechangePhone;
