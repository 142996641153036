<template>
  <div class="app-contianer orderAccount f36">
    <div class="content-top">
      <div class="shopInfo-box">
        <img :src="shopInfo.avatar" alt="" />
        <div class="info">
          <div>
            <strong>{{ billInfo.shopName }}</strong>
          </div>
          <p class="f34"> <span class="grey">店铺ID: </span>{{ billInfo.shopId }} </p>
          <p class="f34"> <span class="grey">订单时间范围: </span>{{ billInfo.rangeTime }} </p>
        </div>
      </div>

      <van-divider />

      <div class="account-box">
        <van-row>
          <van-col span="6"><strong>对账单号:</strong></van-col>
          <van-col span="18" class="tar"
            ><strong>{{ billInfo.orderId }}</strong></van-col
          >
        </van-row>
        <van-row class="margin-t1">
          <van-col span="6"><strong>应付金额:</strong></van-col>
          <van-col span="18" class="tar"
            ><strong>￥{{ billInfo.realAmount }}</strong></van-col
          >
        </van-row>
        <van-row class="margin-t2" v-if="billInfo.param.custAmount === '1'">
          <van-col span="6" class="grey">用户返现:</van-col>
          <van-col span="18" class="tar">￥{{ billInfo.custAmount }}</van-col>
        </van-row>
        <van-row class="margin-t1" v-if="billInfo.param.fee === '1'">
          <van-col span="6" class="grey">手续费:</van-col>
          <van-col span="18" class="tar">￥{{ billInfo.fee }}</van-col>
        </van-row>
        <van-row class="margin-t1">
          <van-col span="6" class="grey">订单数量:</van-col>
          <van-col span="18" class="tar">
            (美团 {{ billInfo.mtNum | emptyFileter }} 单 饿了么 {{ billInfo.elmNum | emptyFileter }} 单) 共 {{ billInfo.orderNum }} 单
          </van-col>
        </van-row>

        <van-divider />

        <van-row>
          <van-col span="6"><strong>支付状态:</strong></van-col>
          <van-col span="18" class="tar">
            <strong v-if="billInfo.status === 'REPAY'">待支付</strong>
            <strong v-if="billInfo.status === 'PAIED'">已支付</strong>
          </van-col>
        </van-row>
        <template v-if="billInfo.status === 'PAIED'">
          <van-row class="margin-t1">
            <van-col span="6" class="grey">支付时间:</van-col>
            <van-col span="18" class="tar">{{ billInfo.payTime }}</van-col>
          </van-row>
          <van-row class="margin-t1">
            <van-col span="6" class="grey">支付单号:</van-col>
            <van-col span="18" class="tar">{{ billInfo.trxid }}</van-col>
          </van-row>
          <van-row class="margin-t1">
            <van-col span="6" class="grey">备注:</van-col>
            <van-col span="18" class="tar">{{ billInfo.remark }}</van-col>
          </van-row>
        </template>
      </div>
    </div>

    <ul class="orderList-box">
      <li class="page-item" v-for="item in orderList" :key="item.logId">
        <div class="channel">
          <img class="icon" :src="item.channelIcon" alt="" />
          渠道订单号: {{ item.note }}
          <span class="f30 mainColor margin-lr2" v-clipboard:copy="item.note" v-clipboard:success="oncopy"> 复制 </span>
        </div>
        <div class="f38 margin-tb2">{{ item.spuName }}</div>
        <p>
          <!-- <span>满￥{{item.amount}} 返￥{{item.shopRealAmount}}</span> -->
          <!-- <span class="fr grey f32" @click="showRequire('图文反馈')">查看试吃要求</span> -->
          <span class="fr grey f32" @click="showRequire(item.detailDesc)"> 查看试吃要求 </span>
        </p>
        <p class="grey f34">报名时间: {{ item.createTime | timeFilter }}</p>
        <div class="commitPics" @click="showPics(item.commitPics)">
          <div class="picture" v-for="(imgUrl, index) in item.commitPics" :key="index">
            <img v-if="imgUrl" :src="imgUrl" />
          </div>
        </div>
        <div class="flex flex-jc-end margin-tb2" v-if="custMsg.userType === 'AGENT'">
          <div class="red f34" @click="freezeUser(item)"> 冻结该用户</div>
        </div>
      </li>
    </ul>

    <div class="pay-box">
      <div class="submitBut" v-if="billInfo.status === 'REPAY'" @click="open = true"> 立即支付 </div>
    </div>

    <van-popup v-model="open" position="bottom" round>
      <div class="popup-content">
        <div class="popup-title f40"><strong>支付确认</strong></div>
        <div class="row f40"> <span class="grey">店铺名称: </span>{{ billInfo.shopName }} </div>
        <div class="row f40"> <span class="grey">订单时间范围: </span>{{ billInfo.rangeTime }} </div>
        <div class="row f40"> <span class="grey">付款金额: </span>￥{{ billInfo.realAmount }} </div>
        <div class="row f40">
          <span class="grey"> 备 注 : </span>
          <input type="text" v-model="remark" placeholder="请输入备注" />
        </div>
      </div>
      <div class="submitBut" v-if="billInfo.status === 'REPAY'" @click="payAccount()"> 立即支付 </div>
    </van-popup>
  </div>
</template>

<script>
import { mallApi, tfoodApi } from '@/api/index'
import { mapState } from 'vuex'
import { Dialog, Toast, ImagePreview } from 'vant'
import { dateFormat } from '@/utils/utils'
import wx from 'weixin-js-sdk'
import thechangePhone from '@/components/changePhone.js'

export default {
  name: 'BillAccount',
  data() {
    return {
      billId: '',
      orderList: [],
      shopInfo: {},
      billInfo: {
        param: {}
      },
      page: 1,
      pageSize: 5,
      loadingText: '请稍等',
      loading: false,
      flag: false, // 分页是否完成标识

      open: false,
      remark: ''
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'agentConfig'])
  },
  filters: {
    timeFilter(val) {
      return dateFormat(new Date(val))
    },
    emptyFileter(val) {
      return typeof val === 'undefined' ? '--' : val
    }
  },
  created() {
    // 未绑定手机号
    if (this.custMsg.phoneUser === 'N') {
      thechangePhone()
    }
    this.billId = this.$route.query.billId
    this.getOrderInfo()
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    getOrderInfo() {
      let params = {
        trxCode: 'XF919',
        page: this.page,
        pageSize: this.pageSize,
        shopType: '030',
        orderId: this.billId
      }
      this.loading = true
      mallApi(params).then((res) => {
        this.loading = false
        let { data, rspCode } = res
        if (rspCode === '0000') {
          this.shopInfo = data.shopInfo
          this.billInfo = data.billInfo
          this.billInfo.param = JSON.parse(this.billInfo.param)

          if (data.orderList.length < this.pageSize) {
            this.flag = true
          } else {
            this.flag = false
          }
          data.orderList.forEach((item) => {
            if (item.commitPics) {
              item.commitPics = JSON.parse(item.commitPics)
            } else {
              item.commitPics = []
            }
          })

          this.orderList = this.orderList.concat(data.orderList)
        }
      })
    },

    // 支付
    payAccount() {
      if (this.agentConfig.PARTNER_FLAG != 'Y') {
        this.$toast('平台未配置服务商, 请联系管理员')
        return
      }

      if (this.loading) return

      // 未绑定手机号
      if (this.custMsg.phoneUser === 'N') {
        thechangePhone()
        return
      }

      let params = {
        trxCode: 'XF181',
        requestType: 'MP',
        payTrxCode: 'TC003',
        orderId: this.billId,
        remark: `付款人:${this.custMsg.custId}。${this.remark}`
      }
      this.loading = true
      let toast = Toast.loading({
        duration: 0, // 持续展示
        forbidClick: true,
        message: '支付中...'
      })
      mallApi(params).then((res) => {
        this.loading = false
        this.open = false
        toast.clear()
        if (res.rspCode === 'P000') {
          var codeUrl = res.payInfo
          wx.chooseWXPay({
            timestamp: codeUrl.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: codeUrl.nonceStr, // 支付签名随机串，不长于 32 位
            package: codeUrl.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: codeUrl.signType, // 微信支付V3的传入RSA,微信支付V2的传入格式与V2统一下单的签名格式保持一致
            paySign: codeUrl.paySign, // 支付签名
            success: function (res) {
              this.$toast.success('支付成功')
            },
            fail(err) {
              this.$toast.fail(err.errMsg)
            }
          })
        } else if (res.rspCode === '0006') {
          this.$toast(res.rspMsg)
          this.$router.push({ path: '/signin' })
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },

    // 预览图片
    showPics(array) {
      ImagePreview(array)
    },

    // 显示试吃要求
    showRequire(val) {
      if (val) {
        Dialog({ title: val, confirmButtonColor: '#9E7BFF' })
      }
    },

    oncopy() {
      Toast('复制成功')
    },

    // 冻结用户
    freezeUser(row) {
      console.log(row)

      let params = {
        trxCode: 'MS144',
        custId: row.custId,
        status: 'FREEZE'
      }

      Dialog.confirm({
        title: '系统提示',
        message: '您确定冻结该用户吗?'
      }).then(() => {
        tfoodApi(params).then((res) => {
          if (res.rspCode === 200) {
            uni.showToast({
              title: '操作成功',
              icon: 'success'
            })
          }
        })
      })
    },

    // 滚动监听
    scrollListener() {
      //总高度=滚动条滚动的高度+cliebtHeight（要是想不到底部就加载数据可以写成）
      //offsetHeight +clientHeight>=scrollHeight-n (n是任意数字)
      const clientHeight = document.documentElement.clientHeight
      const scrollTop = document.documentElement.scrollTop || document.body.scrollTop
      const scrollHeight = document.body.scrollHeight
      var hegit = clientHeight + scrollTop
      var scroll = scrollHeight - 100
      if (hegit >= scroll) {
        if (this.flag === false) {
          this.flag = true
          this.page += 1
          this.getOrderInfo()
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
p {
  margin-top: 1vw;
}

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-top {
  background: #fff;
  padding: 4vw;
}

.shopInfo-box {
  display: flex;
  align-items: center;

  > img {
    width: 20vw;
    height: 20vw;
    margin-right: 2vw;
  }
}

.account-box {
  margin-top: 4vw;
  margin-left: 3vw;
  margin-right: 3vw;
}

.orderList-box {
  margin-bottom: 100px;

  .channel {
    display: flex;
    align-items: center;
    padding-bottom: 2vw;
    border-bottom: 1px solid #eee;

    .icon {
      width: 5vw;
      margin-right: 2vw;
    }
  }

  .picture {
    width: 20vw;
    height: 20vw;
    overflow: hidden;
    display: inline-block;
    margin-right: 4vw;
  }

  .commitPics {
    margin-top: 4vw;
  }
}

.pay-box {
  position: fixed;
  bottom: 0px;
  width: 100%;
}

.popup-content {
  padding: 4vw;

  .popup-title {
    margin-bottom: 6vw;
  }

  .row {
    margin-top: 4vw;
  }
}
</style>
