<template>
  <div class="changePhone">
    <van-popup v-model="show" round class="phone-popup">
      <div class="popup-title">
        绑定手机号码
      </div>
      <div class="form">
        <p>手机号码</p>
        <input type="text" placeholder="请输入手机号码" v-model.number="phone" maxlength="11" />
        <p>验证码</p>
        <div class="code-box">
          <input type="text" placeholder="请输入验证码" v-model.trim="code" autocomplete="one-time-code" maxlength="6" />
          <div class="text">
            <div v-if="num > 0 && num !== 120">{{ num }}秒后可重新获取</div>
            <div v-else @click="getCode()">获取验证码</div>
          </div>
        </div>
      </div>
      <div class="submitBut" @click="submit()">提交</div>
    </van-popup>
  </div>
</template>

<script>
import { mallApi } from '@/api'
import { mapState, mapActions } from 'vuex'

export default {

  name: 'changePhone',
  data() {
    return {
      show: true,
      phone: '',
      code: '',
      num: 0,
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'agentId', 'location', 'cityName', 'address']),
  },
  watch: {
    show(newVal) {
      if (newVal === false) {
        this.cancel() // cancel方法在changePhone.js文件实现
      }
    },
  },
  created() {
    this.getTime()
    // this.phone = this.custMsg.phone
  },
  methods: {
    ...mapActions('user', ['getUserInfo']),

    // 发生手机验证码
    getCode() {
      if (this.phone === '' || this.phone.length < 11) {
        this.$toast('请输入正确的电话号码')
        return
      }

      mallApi({
        trxCode: 'XF172',
        phone: this.phone,
        agentId: this.agentId,
      }).then((res) => {
        if (res.trxCode) {
          sessionStorage.setItem('time', Date.parse(new Date()) / 1000)
          this.$toast('已发送')
          this.getTime()
        } else {
          this.$toast(res.rspMsg)
        }
      })
    },
    getTime() {
      var date2 = Date.parse(new Date()) / 1000
      var time = date2 - sessionStorage.getItem('time')
      this.num = 120 - time
      if (this.num > 0) {
        setTimeout(this.getTime, 1000)
      }
    },

    // 提交
    submit() {
      let params = {
        trxCode: 'XF176',
        phone: this.phone,
        code: this.code,
      }
      mallApi(params).then(res => {
        if (res.trxCode) {
          this.$toast('绑定成功')
          localStorage.setItem('H5token', res.APPLETTOKEN)
          this.$store.commit('user/SET_CUSTMSG', res.custMsg)
          this.show = false

          if (!res.custMsg.cityName) {
            mallApi({
              trxCode: 'XF174',
              longitude: this.location.longitude,
              latitude: this.location.latitude,
              cityName: this.cityName,
              address: this.address,
              oldCityName: ''
            }).then(res => {
              this.$store.commit('user/SET_CUSTMSG', res.custMsg)
            })
          }
        } else {
          this.$toast(res.rspMsg)
        }
        // this.ok()
      })
    }
  }
}
</script>

<style lang='less' scoped>
.phone-popup {
  width: 80vw;
}
.popup-title {
  margin: 4vw;
  font-size: 4vw;
  text-align: center;
}
.form {
  padding: 0 4vw;

  p {
    text-align: left;
    font-size: 4vw;
    color: #999999;
    margin-top: 3vw;
    margin-bottom: 1vw;
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid #dddddd;
    font-size: 4vw;
    color: #333333;
    padding: 3vw;
    box-sizing: border-box;
  }

  .code-box {
    width: 100%;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #dddddd;

    input {
      flex: 1;
      height: 100%;
      border-bottom: none;
    }

    .text {
      font-size: 3vw;
      color: #906af6;
    }
  }
}
</style>